import { useCallback, useEffect, useRef, useState } from 'react'
import { webrtc } from '../../lib'
import { useCameraKitSession } from './useCameraKit'

import { useStreamerRTC } from '../../lib/hooks'

import { socket } from '../../App'

// ask to start
// get media stream
// turn on snap button
const userMediaRequestStateEnum = {
  IDLE: 'IDLE',
  REQUESTING: 'REQUESTING',
  STREAMING: 'STREAMING',
  ERROR: 'ERROR',
  SNAP_STREAMING: 'SNAP_STREAMING',
}

const Streamer = () => {
  const { init } = useCameraKitSession()

  const [userMediaCaptureCurrentState, setUserMediaStreamingCaptureState] = useState(userMediaRequestStateEnum.IDLE)

  const rtcRef = useRef(webrtc.newRTCPeerConnection(socket))

  let mediaStreamRef = useRef(null)
  // let videoRef = useRef(null)

  const handleStartVideo = useCallback(async () => {
    try {
      console.log('[WEBRTC] Starting to capture user media')
      setUserMediaStreamingCaptureState(userMediaRequestStateEnum.REQUESTING)
      const mediaStream = await webrtc.handlers.startCall()
      mediaStreamRef.current = mediaStream
      // videoRef.current.srcObject = mediaStream
      console.log('[WEBRTC] Successfully captured user media. Streaming...')
      setUserMediaStreamingCaptureState(userMediaRequestStateEnum.STREAMING)

      handleStartSnapStreaming()
    } catch (e) {
      console.error('[WEBRTC] Failed to capture user media.', e)
      setUserMediaStreamingCaptureState(userMediaRequestStateEnum.ERROR)
    }
  }, [])

  const handleStartSnapStreaming = useCallback(() => {
    console.log('[WEBRTC] Starting snapchat SDK')
    init(mediaStreamRef.current)
      .then((mediaStream) => {
        mediaStreamRef.current = mediaStream
        // videoRef.current.srcObject = mediaStream
        mediaStream.getTracks().forEach((track) => {
          rtcRef.current.addTrack(track, mediaStream)
        })
        console.log('[WEBRTC] Successfully started snapchat SDK. Streaming...')
        setUserMediaStreamingCaptureState(
          userMediaRequestStateEnum.SNAP_STREAMING
        )
      })
      .catch((e) => {
        console.error('[WEBRTC] Failed to start snapchat SDK.', e)
      })
  }, [init])

  // signaling bindings
  useStreamerRTC(rtcRef)

  useEffect(() => {
    // Эта функция будет вызвана сразу после загрузки компонента
    const timer = setTimeout(() => {
      handleStartVideo();
    }, 2000);
    return () => clearTimeout(timer);
  }, []) // Пустой массив зависимостей означает, что эффект запустится один раз после первого рендера

  return (
    <>
      {/* {userMediaCaptureCurrentState === userMediaRequestStateEnum.IDLE && (
        <button onClick={handleStartVideo}>Start Video</button>
      )}
      {userMediaCaptureCurrentState === userMediaRequestStateEnum.ERROR && (
        <button onClick={handleStartVideo}>Retry</button>
      )}
      {userMediaCaptureCurrentState ===
        userMediaRequestStateEnum.REQUESTING && (
        <span>Requesting User Media...</span>
      )} */}
      {/* <video autoPlay muted ref={videoRef} /> */}
      <canvas id="canvas-output"/>

      {/* <div ref={canvasContainerRef}></div> */}
      {/* <div>
        <button
          disabled={
            userMediaCaptureCurrentState !== userMediaRequestStateEnum.STREAMING
          }
          onClick={handleStartSnapStreaming}
        >
          {userMediaCaptureCurrentState ===
            userMediaRequestStateEnum.STREAMING && 'Enable Snap Streaming'}
          {userMediaCaptureCurrentState ===
            userMediaRequestStateEnum.REQUESTING && 'Please Wait...'}
          {userMediaCaptureCurrentState === userMediaRequestStateEnum.IDLE &&
            'Please Start Video First'}
          {userMediaCaptureCurrentState ===
            userMediaRequestStateEnum.SNAP_STREAMING && ''}
        </button>
      </div> */}
    </>
  )
}
export default Streamer
