const defaultConstraints = {
  audio: false,
  video: {
    width: 1280,
    height: 720,
  },
}

export const startCall = async (constraints = defaultConstraints) => {
  return navigator.mediaDevices.getUserMedia(constraints)
}
