import { bootstrapCameraKit, createMediaStreamSource } from '@snap/camera-kit'
import { useState, useRef, useCallback } from 'react'
import { isArm } from '../../App'

const lensGroupId1 = process.env.REACT_APP_LENS_GROUP_ID_1
const lensGroupId2 = process.env.REACT_APP_LENS_GROUP_ID_2

export function useCameraKitSession() {
  const [isCameraKitSessionReady, setIsCameraKitSessionReady] = useState(false)
  const cameraKitSessionRef = useRef(null)
  const cameraKitRef = useRef(null)
  const cameraKitLensesRef = useRef(null)
  var mediaStream

  const deviceIdMain = useRef(null)
  const isArmArchitecture = useRef(null)
  var cameraName

  // CONSTANTS
  const widthForArm = 2000
  const widthForIntel = 1280
  const heightForArm = 1130
  const heightForIntel = 720

  // APPLY LENS
  const applySnap = useCallback(async (mediaStream) => {
    if (!mediaStream) {
      console.log('no mediaStream')
      return
    }

    if (mediaStream) {
      cameraKitSessionRef.current.pause()
      mediaStream.getVideoTracks()[0].stop()
    }

    const devices = await navigator.mediaDevices.enumerateDevices()
    const cameras = devices.filter(({ kind }) => kind === 'videoinput')
    cameras.forEach((camera) => {
      if (camera.label.includes('FaceTime')) {
        deviceIdMain.current = camera.deviceId
        cameraName = camera.label
        console.log('🏳️‍🌈 device id:', deviceIdMain.current)
        console.log('🏳️‍🌈 device name:', camera.label)
        window.webkit.messageHandlers.didChangeToMacbookCamera.postMessage('didChangeToCamera (init): ' + camera.label)
      }
    })

    const renderWidth = isArmArchitecture.current ?  widthForArm : widthForIntel
    const renderHeight = isArmArchitecture.current ? heightForArm : heightForIntel

    mediaStream = await navigator.mediaDevices.getUserMedia({
      video: { frameRate: { ideal: 30, max: 30 }, deviceId: deviceIdMain.current, aspectRatio: 16/9, width: renderWidth, height: renderHeight },
    })

    const source = createMediaStreamSource(mediaStream, { disableSourceAudio: false })
    await cameraKitSessionRef.current.setSource(source)
    await source.setRenderSize(renderWidth, renderHeight)
    await cameraKitSessionRef.current?.play()

    window.webkit.messageHandlers.debugSwiftHandler.postMessage('🏳️‍🌈 Source size (apply): ' + renderWidth + 'x' + renderHeight + '. Is Apple Silicon: ' + isArmArchitecture.current)
  }, [])

  const getSnapStream = useCallback(() => {
    return cameraKitSessionRef.current?.output.live.captureStream()
  }, [])

  const addListeners = useCallback(() => {
    // send notification that we've got all lenses
    window.webkit.messageHandlers.getAllLensesHandler.postMessage(JSON.stringify(cameraKitLensesRef.current))

    // error listener
    cameraKitSessionRef.current.events.addEventListener('error', (event) => {
      if (event.detail.error.name === 'LensExecutionError') {
        let info = `❗️❗️❗️ Lens ${event.detail.lens.name} encountered an error and was removed. Please pick a different lens.`
        console.log(info)
        window.webkit.messageHandlers.debugSwiftHandler.postMessage(info)
      }
      window.webkit.messageHandlers.debugSwiftHandler.postMessage(event.detail)
      // prevent React's listener from firing
      event.stopImmediatePropagation()
      // prevent the browser's console error message
      event.preventDefault()
    })

    // listen to lens stats
    cameraKitRef.current.metrics.addEventListener('lensView', (event) => {
      window.webkit.messageHandlers.debugSwiftHandler.postMessage(event.detail)
      console.debug(event.detail)
    })

    // change lens
    window.addEventListener('changeLens', (event) => {
      const lensId = event.detail.data
      const lens = cameraKitLensesRef.current.find((lens) => lens.id === lensId)
      cameraKitSessionRef.current.play()
      if (lens) cameraKitSessionRef.current.applyLens(lens)
      window.webkit.messageHandlers.getLensInfoHandler.postMessage(JSON.stringify(lens))
    })

    // change lens for macOS Sonoma or greater (this a workaround for SnapKit bug)
    window.addEventListener('changeLensForSonoma', (event) => {
      const lensId = event.detail.data
      document.cookie = "lensId=" + encodeURIComponent(lensId);
      window.webkit.messageHandlers.debugSwiftHandler.postMessage('👩‍🎤 selected lens: ' + lensId)
      window.location.reload()
    })

    // remove lens
    window.addEventListener('removeLens', (event) => {
      cameraKitSessionRef.current.removeLens()
      window.webkit.messageHandlers.didRemoveLensHandler.postMessage('didRemoveLens')
    })

    // listener to switch to Macbook camera and assign isArmArchitecture
    window.addEventListener('changeToMacbookCamera', (event) => {
      isArmArchitecture.current = event.detail.data === 'true'
      
      window.webkit.messageHandlers.didChangeToMacbookCamera.postMessage('🧿 didChangeToMacbookCamera (apply): ' + cameraName + '. Is Apple Silicon: ' + isArmArchitecture.current)
      applySnap(mediaStream)
    })
    
    let mediaRecorder
    let downloadUrl

    window.addEventListener('startRecordingVideo', (event) => {
        const outputStream = cameraKitSessionRef.current.output.live.captureStream()

        const options = {
            videoBitsPerSecond: 2500000,
            mimeType: "video/mp4",
          }
          
        // https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder
        mediaRecorder = new MediaRecorder(outputStream, options)
        mediaRecorder.addEventListener('dataavailable', (event) => {
            if (!event.data.size) {
                console.warn('No recorded data available')
                window.webkit.messageHandlers.debugSwiftHandler.postMessage('❌ No recorded data available')
                return
            }

            downloadUrl = window.URL.createObjectURL(event.data)

            const link = document.createElement('a')
            link.setAttribute('style', 'display: none')
            link.href = downloadUrl
            link.download = 'camera-kit-web-recording.mp4'
            link.click()
            link.remove()

            window.webkit.messageHandlers.didGetRecordedVideoUrl.postMessage(downloadUrl)
        })
        mediaRecorder.start()
    })

    window.addEventListener('stopRecordingVideo', (event) => {
        mediaRecorder?.stop()
    })
  }, [applySnap, mediaStream])

  // INIT SNAP CAMERA
  const init = useCallback(
    async (mediaStream) => {
      try {
        let cameraKit = await bootstrapCameraKit({ 
          apiToken: process.env.REACT_APP_API_TOKEN,
          logger: "console",
          logLevel: "debug"
        })
        cameraKitRef.current = cameraKit

        var liveRenderTarget = document.getElementById('canvas-output');
        cameraKitSessionRef.current = await cameraKit.createSession({ liveRenderTarget })

        const { lenses } = await cameraKit.lensRepository.loadLensGroups([ lensGroupId1, lensGroupId2 ])
        cameraKitLensesRef.current = lenses
        isArmArchitecture.current = isArm

        // apply test lens
        // cameraKitSessionRef.current.applyLens(lenses[0])
        // if (lenses.length > 0) {
        //     const lens = await cameraKit.lensRepository.loadLens("348b74a6-89f9-4437-9282-020900ce133b", "df34f65a-35f5-493d-a82b-ae4174fd77fb",)
        //     await cameraKitSessionRef.current.applyLens(lens)
        // }

        // apply saved lens
        const lensId = decodeURIComponent(document.cookie.replace(/(?:(?:^|.*;\s*)lensId\s*=\s*([^;]*).*$)|^.*$/, "$1"));
        if (lensId) {
          const lens = cameraKitLensesRef.current.find((lens) => lens.id === lensId)
          cameraKitSessionRef.current.applyLens(lens)
          window.webkit.messageHandlers.debugSwiftHandler.postMessage('👩‍🎤 selected lens: ' + lens.id)
        }

        addListeners()

        // SETUP
        setIsCameraKitSessionReady(true)
        await applySnap(mediaStream)
        
        return getSnapStream()
      } catch (err) {
        console.error(err)
        setIsCameraKitSessionReady(false)
      }
    },
    [applySnap, getSnapStream, addListeners]
  )

  return {
    init,
    applySnap,
    getSnapStream,
    isReady: isCameraKitSessionReady,
    lenses: cameraKitLensesRef.current,
  }
}
