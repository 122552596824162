import { socket } from '../../App'
import { globalId } from '../../App'

export function newRTCPeerConnection() {

  const conn = new RTCPeerConnection({
    iceServers: [
      {
        urls: 'stun:stun.l.google.com:19302',
      },
    ],
  })
  conn.onicecandidate = ({ candidate }) => {
    console.log('onicecandidate candidate', candidate)
    if (!candidate?.candidate) return
    const toSend = {
      sdp: candidate.candidate,
      sdpMLineIndex: candidate.sdpMLineIndex,
      sdpMid: candidate.sdpMid,
    }
    if (socket.readyState === WebSocket.OPEN) {
      
        // socket.send(
        //   JSON.stringify({
        //     id: globalId,
        //     type: 'candidate',
        //     candidate: toSend,
        //   })
        // )
    }
  }

  conn.onicecandidateerror = (e) => {
    console.log(`[WEBRTC] onicecandidateerror: ${e.errorText}`)
  }
  conn.onconnectionstatechange = (e) => {
    switch (conn.connectionState) {
      case 'new':
      case 'connected':
        console.log('[WEBRTC] connected')
        break
      case 'connecting':
        console.log('[WEBRTC] connecting')
        break
      case 'disconnected':
        console.log('[WEBRTC] disconnected')
        break
      case 'failed':
        restartIceConnection(conn);
        console.log('[WEBRTC] failed')
        break
      case 'closed':
        console.log('[WEBRTC] closed')
        break
      default:
        console.log('[WEBRTC] connection state change', conn.connectionState)
        break
    }
  }

  conn.oniceconnectionstatechange = (e) => {
    console.log(`[WEBRTC] ICE connection state: ${conn.iceConnectionState}`)
  }

  return conn
}


function restartIceConnection(conn) {
  if (!conn) {
    console.log('[WEBRTC] No RTC connection available for ICE restart');
    return;
  }

  console.log('[WEBRTC] Attempting to restart ICE connection');
  conn.createOffer({ iceRestart: true })
    .then((offer) => {
      console.log('[WEBRTC] New offer created for ICE restart', offer);
      return conn.setLocalDescription(offer)
        .then(() => {
          // Отправка нового предложения через WebSocket
          socket.send(JSON.stringify({
            id: globalId,
            type: 'offer',
            sessionDescription: { sdp: offer.sdp },
          }));
          console.log('[WEBRTC] New offer sent for ICE restart');
        })
        .catch(error => console.error('[WEBRTC] Error setting local description:', error));
    })
    .catch(error => console.error('[WEBRTC] Error creating offer for ICE restart:', error));
}