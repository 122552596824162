export const onSetSessionDescriptionError = (err) =>
  console.log(`[WEBRTC] Failed to set session description: ${err.toString()}`)

export const onSetLocalSuccess = () =>
  console.log(`[WEBRTC] setLocalDescription complete`)

export const onSetRemoteSuccess = () =>
  console.log('[WEBRTC] setRemoteDescription complete')

export const onCreateSessionDescriptionError = (error) =>
  console.log(`Failed to create session description: ${error.toString()}`)

export const onAddIceCandidateSuccess = () => {
  console.log(`[WEBRTC] addIceCandidate success`)
}

export const onAddIceCandidateError = (error) => {
  console.log(`[WEBRTC] Failed to add ICE Candidate: ${error.toString()}`)
}

export const onIceStateChange = (evt) => {
  console.log('[WEBRTC] ICE state change event: ', evt)
}
