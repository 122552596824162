import { Streamer, Configuration } from '../../components'

export default function StreamingRoom() {
  return (
    <div className="streaming-room">
      <Streamer />
      <Configuration />
    </div>
  )
}
