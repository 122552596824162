import { useCallback, useEffect, useState } from 'react';
import './App.css';
import StreamingRoom from './pages/StreamingRoom/StreamingRoom';

export var globalId = 'no_id';
export var isArm = false;
export let socket = null;

function App() {
  const [socketReady, setSocketReady] = useState(false);

  const startSocket = useCallback(() => {
    try {
      socket = new WebSocket('wss://beams-test.dressx.co');
      // socket = new WebSocket('ws://192.168.8.10:8080');
      // socket = new WebSocket('ws://172.20.10.2:8080');
      // socket = new WebSocket('ws://192.168.142.18:8080');
      socket.onopen = () => {
        console.log('[WEBSOCKET] socket ready');
        setSocketReady(true);
        setTimeout(() => {
          socket.send(JSON.stringify({ type: 'register_main', id: globalId }))
          socket.send(JSON.stringify({ id: globalId, type: 'ready', role: 'streamer' }))
        }, 2000);
      };
    } catch (err) {
      console.error(err)
    }
  }, [setSocketReady]);

  useEffect(() => {
    // Parse len_id from the URL and update globalId
    const queryParams = new URLSearchParams(window.location.search);
    const lenId = queryParams.get('len_id');
    const arm = queryParams.get('isArm');
    if (lenId) {
      globalId = lenId;
      isArm = arm === 'true'
      console.log('Updated globalId:', globalId);
      window.webkit.messageHandlers.debugSwiftHandler.postMessage('🆔 WebRtc Client Id (param): ' + globalId);
      startSocket();
    }

    // Cleanup
    return () => {
      // 
    };
  }, [startSocket]);

  if (!socketReady && socket?.readyState !== 1) {
    console.log('Socket is not ready yet');
    return (
      <button style={{ display: "none" }} onClick={startSocket}>
        Click here to start the socket connection.
      </button>
    );
  }

  return (
    <div className="App">
      <StreamingRoom />
    </div>
  );
}

export default App;