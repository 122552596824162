import { useCallback, useEffect } from 'react'
import { socket } from '../../App'
import webrtc from '../webrtc'
import { globalId } from '../../App'

export const useStreamerRTC = (rtcRef) => {

  const setAnswer = useCallback(
    async (answer) => {
      if (rtcRef.current === null) {
        console.log("no rtc connection to set answer's remote description")
        return
      }
      rtcRef.current
        .setRemoteDescription({
          type: answer.type,
          sdp: answer.sessionDescription.sdp,
        })
        .then(webrtc.callbacks.onSetRemoteSuccess)
        .catch(webrtc.callbacks.onCreateSessionDescriptionError)
    },
    [rtcRef]
  )

  const setCandidate = useCallback(
    async (candidate) => {
      console.log('setCandidate candidate', candidate)
      if (rtcRef.current === null) {
        console.log('no peer connection to set candidate')
        return
      }
      if (!candidate) {
        return
      }
      if (!candidate.sdpMLineIndex && !candidate.sdpMid) {
        return
      }
      if (!candidate.candidate) {
        if (!candidate.sdp) return
        candidate.candidate = candidate.sdp
      }

      rtcRef.current
        ?.addIceCandidate(candidate)
        .then(webrtc.callbacks.onAddIceCandidateSuccess)
        .catch(webrtc.callbacks.onAddIceCandidateError)
    },
    [rtcRef]
  )

  const handleNewGuest = useCallback(async () => {
    // we are already streaming send him an offer
    if (!rtcRef.current) {
      console.log('no rtc connection to send offer')
    }
    console.log('Sending offer to guest')
    rtcRef.current
      .createOffer()
      .then((offer) => {
        socket.send(
          JSON.stringify({
            id: globalId,
            type: offer.type,
            sessionDescription: { sdp: offer.sdp },
          })
        )
        rtcRef.current
          .setLocalDescription(offer)
          .then(webrtc.callbacks.onSetLocalSuccess)
          .catch(webrtc.callbacks.onSetSessionDescriptionError)
      })
      .catch(webrtc.callbacks.onCreateSessionDescriptionError)
  }, [rtcRef])

  const handleMessages = useCallback(
    (e) => {
      const data = JSON.parse(e.data)
      console.log(`[WEBSOCKET] received message: ${data.type}`)
      switch (data.type) {
        case 'answer':
          setAnswer(data)
          break
        case 'offer':
          console.log('[WEBSOCKET] ignoring offer send to streaming client')
          break
        case 'candidate':
          setCandidate(data)
          break
        case 'ready':
          break
        case 'want-to-watch':
          handleNewGuest(data)
          break
        default:
          console.log("[WEBSOCKET] unknown message type: '" + data.type + "'")
      }
    },
    [handleNewGuest, setAnswer, setCandidate]
  )

  useEffect(() => {
    socket.addEventListener('message', handleMessages)

    return () => {
      socket.removeEventListener('message', handleMessages)
    }
  }, [handleMessages])

  useEffect(() => {
    // socket.send(JSON.stringify({ type: 'register', id: globalId }))
    // socket.send(JSON.stringify({ id: globalId, type: 'ready', role: 'streamer' }))
  }, [])
}
